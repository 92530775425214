import { Typography, Button, Grid, Paper } from "@mui/material";

import { styled } from "@mui/material/styles";
import ProjectCard from "./ProjectCard";

import { red, green, blue, yellow, purple } from "@mui/material/colors";
import Footer from "./Footer";

const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up("xs")]: {
    padding: "1em",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "2em",
  },
  [theme.breakpoints.up("md")]: {
    padding: "4em",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "5em",
  },
}));

const RootInternal = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up("xs")]: {
    padding: "2em",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "3em",
  },
  [theme.breakpoints.up("md")]: {
    padding: "4em",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "7em 12em",
  },
}));


export function Home() {
  return (
    <div>
      <div className="videoContainer">
        <div className="videoContent">Core Excellence Solutions</div>
        <div className="video-overlay"></div>
        <div className="videoWrapper">
          <video autoPlay loop muted>
            <source
              src="https://storage.cloud.google.com/coreexcellence_website_assets/ce-solutions-logo-zip-file/video%20web.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div style={{ marginTop: "7rem", marginBottom: "5rem" }}>
        <Root>
          <div className="whyUs">
            <RootInternal>
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="h4"
                  style={{ fontFamily: "'Exo', sans-serif" }}
                >
                  Why Core Excellence Solutions
                </Typography>
                <br></br>
                <br></br>
                <p
                  style={{
                    fontFamily: "'Exo', sans-serif",
                    fontSize: "1.1rem",
                    textAlign: "justify",
                  }}
                >
                  What sets top achievers in an organization apart is their
                  exceptional ability to accomplish more with less. Companies
                  aspire to cultivate the mindset, emotional intelligence,
                  technical expertise, leadership, innovation, adaptability,
                  communication, teamwork, and problem-solving skills that these
                  high-performing individuals embody.
                  <br></br>
                  <br></br>
                  This is precisely where we excel. We offer a proven model that
                  can replicate top achievers within your organization. By
                  leveraging our expertise, you can surpass your organizational
                  objectives with unprecedented excellence and confidence,
                  achieving results beyond your expectations.
                </p>
                  <Button
                    style={{
                      margin: "2em",
                      // marginBottom: "0",
                      padding: "1.5em",
                      backgroundColor: "#003366",
                      color: "white",
                      fontWeight: "bolder",
                      borderRadius: "6rem",
                      fontFamily: "'Exo', sans-serif",
                      fontSize: "14px",
                      width: "60%",
                    }}
                    href="/services"
                  >
                    Our Services
                  </Button>
              </div>
            </RootInternal>
          </div>
        </Root>
      </div>

      <div style={{ margin: "1em", fontFamily: "'Exo', sans-serif" }}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ProjectCard
              title={"Team Empowerment"}
              description={"Energize your team to achieve more"}
              img={
                "https://storage.cloud.google.com/coreexcellence_website_assets/ce-solutions-logo-zip-file/team-perform.webp"
              }
            />
          </Grid>
          <Grid item xs={4}>
            <ProjectCard
              title={"Maximize Individual Potential"}
              description={"Raising Individual Productivity"}
              img={
                "https://storage.cloud.google.com/coreexcellence_website_assets/ce-solutions-logo-zip-file/maximize-individual.webp"
              }
            />
          </Grid>
          <Grid item xs={4}>
            <ProjectCard
              title={"Emotional Resilience"}
              description={"Functioning under emotional stress"}
              img={
                "https://storage.cloud.google.com/coreexcellence_website_assets/ce-solutions-logo-zip-file/emotional-resil.webp"
              }
            />
          </Grid>
        </Grid>
      </div>
      <Footer/>
    </div>
  );
}
