import {
  Grid,
  Paper,
  Button,
  Container,
  ThemeProvider,
  CssBaseline,
  createTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import logo from "./logo-color.png";
import { useState } from "react";
import Footer from "./Footer";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "inherit",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: "4rem",
  borderRadius: "0px",
  boxShadow: "none",
}));

function SimpleTopNavigation() {
  const query = useLocation();

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={{
        backgroundColor: "#003366",
        zIndex: 101,
        fontFamily: "'Exo', sans-serif",
      }}
      position={"fixed"}
    >
      <Grid item xs={6}>
        <Item>
          <img
            src={
              "https://storage.cloud.google.com/coreexcellence_website_assets/ce-solutions-logo-zip-file/png/logo-color.png"
            }
            alt="123"
            loading="lazy"
            style={{ width: "100%", maxWidth: "150px", height: "auto" }}
          />
        </Item>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Item>
          <ButtonLink
            to="/"
            text="Home"
            isActive={query.pathname === "/"}
          ></ButtonLink>
          <ButtonLink
            to="/services"
            text="Services"
            isActive={query.pathname === "/services"}
          ></ButtonLink>
        </Item>
      </Grid>
    </Grid>
  );
}

const theme = createTheme({
  typography: {
    fontFamily: ["Exo", "sans-serif"].join(","),
    // fontWeightBold: 600
  },
});

export default function PageTheme({ children }) {
  return (
    // <ThemeProvider theme={theme}>
    //   <CssBaseline />
    <div style={{ fontFamily: "Exo" }}>
      <SimpleTopNavigation />
      {children}
      {/* <Footer/> */}
    </div>
    // </ThemeProvider>
  );
}

export function PageLinks() {
  return <></>;
}

export function ButtonLink({ to, text, isActive, onClick }) {
  const [isHover, setIsHover] = useState(false);

  return (
    <Button
      href={to}
      component="button"
      variant="contained"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{
        backgroundColor: "inherit",
        boxShadow: "none",
        color: "white",
        padding: "1em",
        paddingBottom: "0.2em",
        paddingLeft: "1em",
        fontSize: "18px",
      }}
    >
      <div
        style={{
          borderBottom: isActive || isHover ? "1px solid white" : "0",
          fontWeight: isHover || isActive ? "bolder" : "normal",
          textTransform: "capitalize",
        }}
      >
        {text}
      </div>
    </Button>
  );
}
