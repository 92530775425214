import { styled } from "@mui/material/styles";

import Footer from "./Footer";
import ServicesImageCard from "./ServicesImageCard";
import { red, green, blue, yellow, purple } from "@mui/material/colors";

const ServicesPageHeading = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "34px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "44px",
  },
}));

const ServicesPageAround = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.between("xs", "sm")]: {
    padding: "0.1rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    padding: "1rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    padding: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "3rem",
  },
  [theme.breakpoints.up("xl")]: {
    padding: "6rem",
  },
}));

const ServicesPageContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "10px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "36px",
  },
}));

export default function Services() {
  return (
    <div
      style={{
        position: "relative",
        top: 180,
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ServicesPageHeading>
        <span>Our Services</span>
      </ServicesPageHeading>
      <ServicesPageAround>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              margin: "0.8rem",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "50%" }}>
              <ServicesImageCard
                img={
                  "https://storage.cloud.google.com/coreexcellence_website_assets/ce-solutions-logo-zip-file/team-perform.webp"
                }
                title={"Team Empowerment"}
              />
            </div>
            <div
              style={{ width: "50%", textAlign: "justify", marginLeft: "1rem" }}
            >
              <ServicesPageContent>
                <span>
                  At CoreExcellence Solutions, we offer tailored services for
                  team empowerment. Our programs focus on enhanced communication
                  and leadership development. We provide resources and support
                  for individual growth, leading to increased productivity and
                  overall performance. Team empowerment is the key to unlocking
                  success in your organization.
                </span>
              </ServicesPageContent>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              margin: "2rem",
              justifyContent: "space-evenly",
            }}
          >
            <div style={{ width: "50%" }}>
              <ServicesImageCard
                img={
                  "https://storage.cloud.google.com/coreexcellence_website_assets/ce-solutions-logo-zip-file/maximize-individual.webp"
                }
                title={"Maximize Individual Potential"}
              />
            </div>
            <div
              style={{ width: "50%", textAlign: "justify", marginLeft: "1rem" }}
            >
              <ServicesPageContent>
                <span>
                  Maximizing individual potential through the development of
                  intrapersonal and social skills is crucial. <br></br>
                  At CoreExcellence Solutions, we focus on unlocking individual
                  potential by honing intrapersonal and social skills. Our
                  programs empower individuals to excel personally and in their
                  interactions with others, fostering growth and success.
                </span>
              </ServicesPageContent>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              margin: "2rem",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "50%" }}>
              <ServicesImageCard
                img={
                  "https://storage.cloud.google.com/coreexcellence_website_assets/ce-solutions-logo-zip-file/emotional-resil.webp"
                }
                title={"Emotional Resilence"}
              />
            </div>
            <div
              style={{ width: "50%", textAlign: "justify", marginLeft: "1rem" }}
            >
              <ServicesPageContent>
                <span>
                  In our uniquely tailored approach, we are dedicated to
                  empowering your valued team members to consistently perform at
                  their best, even when faced with high-pressure situations. We
                  achieve this by fostering their problem-solving capabilities
                  through the nurturing of emotional resilience and maturity,
                  ensuring they not only meet but surpass expectations.
                </span>
              </ServicesPageContent>
            </div>
          </div>
        </div>
      </ServicesPageAround>
      <Footer />
    </div>
  );
}
