import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { red, green, blue, yellow, purple } from "@mui/material/colors";

const RootFooterHeading = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "34px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "44px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "50px",
  },
}));

const RootFooterBox = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up("xs")]: {
    // backgroundColor: red[500],
    // fontSize: "20px",
    // padding: "1em",
    // margin: "1rem",
  },
  [theme.breakpoints.up("sm")]: {
    // backgroundColor: green[500],
    padding: "3em",
    // width: "90%",
  },
  [theme.breakpoints.up("md")]: {
    // backgroundColor: yellow[500],
    padding: "4em",
    // width: "70%",
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: purple[500],
    padding: "6em",
    // margin: "1rem",
    // padding: "7em",
    // width: "60%",
  },
  [theme.breakpoints.up("xl")]: {
    padding: "8em",
  },
}));

const RootFooterContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up("xs")]: {
    // backgroundColor: red[500],
    fontSize: "10px",
    // padding: "1em",
    // margin: "1rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "26px",
  },
}));

const ItemRight = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  //   textAlign: "center",
  color: theme.palette.text.secondary,
  display: "block",
}));

const ItemLeft = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "10px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "30px",
  },
}));

export default function Footer() {
  return (
    <RootFooterBox>
      <Grid container spacing={2} style={{ wordWrap: "break-word" }}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginBottom: "1rem" }}>
              <ItemLeft>
                <RootFooterHeading>
                  <span>Business Hours</span>
                </RootFooterHeading>
              </ItemLeft>
            </Grid>
            <Grid item xs={6}>
              <ItemLeft>
                {/* <RootFooterContent> */}
                <span>Mon-Fri</span>
                {/* </RootFooterContent> */}
              </ItemLeft>
            </Grid>
            <Grid item xs={6}>
              <ItemLeft>
                {/* <RootFooterContent> */}
                <span variant={"subtitle1"}>9am - 5pm</span>
                {/* </RootFooterContent> */}
              </ItemLeft>
            </Grid>
            <Grid item xs={6}>
              <ItemLeft>
                {/* <RootFooterContent> */}
                <span variant={"subtitle1"}>Sat-Sun</span>
                {/* </RootFooterContent> */}
              </ItemLeft>
            </Grid>
            <Grid item xs={6}>
              <ItemLeft>
                {/* <RootFooterContent> */}
                <span>Appointment Only</span>
                {/* </RootFooterContent> */}
              </ItemLeft>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginBottom: "1rem" }}>
              <ItemRight>
                <RootFooterHeading>
                  <span>Contact Info</span>
                </RootFooterHeading>
              </ItemRight>
            </Grid>
            <Grid item xs={12}>
              <ItemRight>
                <RootFooterContent>
                  <span variant={"subtitle1"}>+447561576016</span>
                </RootFooterContent>
              </ItemRight>
            </Grid>
            <Grid item xs={12}>
              <ItemRight>
                <RootFooterContent>
                  <span variant={"subtitle1"}>Address: LU1 2EQ</span>
                </RootFooterContent>
              </ItemRight>
            </Grid>
            <Grid item xs={12}>
              <ItemRight>
                <RootFooterContent>
                  <span>joshua.obasaju@coreexcellencesolutions.com</span>
                </RootFooterContent>
              </ItemRight>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RootFooterBox>
  );
}
