import "./services.css";
import { styled } from "@mui/material/styles";

import { red, green, blue, yellow, purple } from "@mui/material/colors";

const CenteredText = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "34px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "46px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "58px",
  },
}));

export default function ServicesImageCard({ img, title }) {
  return (
    <div class="container">
      <img src={img} alt="Snow" style={{ width: "100%" }} />

      <CenteredText>
        <div class="centered">{title}</div>
      </CenteredText>
    </div>
  );
}
