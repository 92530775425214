import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { styled } from "@mui/material/styles";

import { red, green, blue, yellow, purple } from "@mui/material/colors";

const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up("xs")]: {
    fontSize: "0.6em",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.5em",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8em",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5em",
  },
}));

const RootForP = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up("xs")]: {
    fontSize: "0.3em",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "0.6em",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.9em",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.1em",
  },
}));

export default function ProjectCard({ title, description, img }) {
  return (
    <Card style={{ textAlign: "center" }} component={"div"}>
      <CardMedia
        component="img"
        image={img}
        alt="green iguana"
        // height= ""
      />
      <CardContent>
        <Root>
          <span
            style={{
              fontFamily: "'Exo', sans-serif",
              //   fontSize: "3em",
              paddingLeft: "0.1rem",
            }}
          >
            {title}
          </span>
        </Root>

        <RootForP>
          <p
            // variant="subtitle1"
            color="text.secondary"
            style={{ fontFamily: "'Exo', sans-serif" }}
          >
            {description}
          </p>
        </RootForP>
      </CardContent>
    </Card>
  );
}
