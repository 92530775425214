import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PageTheme from "./Nav";
import { Home } from "./Home";
import { createTheme } from "@mui/material";
import Services from "./Services";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PageTheme>
        <Home></Home>
      </PageTheme>
    ),
  },
  {
    path: "/services",
    element: (
      <PageTheme>
        <Services></Services>
      </PageTheme>
    ),
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
